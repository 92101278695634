import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"

const RetailProducts = () => (
  <Layout>
    <SEO title="Retail Products" />
    <HeroInner title="Retail Products" />
  </Layout>
)

export default RetailProducts